import { AppProviders } from "../../components/UIComponents/AppProviders";
import { BaseCompanyList } from "../../components/UIComponents/BaseCompanyList";
import { MovingCompanies } from "./verticals/MovingCompanies";
import { styles } from "./verticals/pageLayoutStyles";

export const App = () => (
	<AppProviders>
		<BaseCompanyList styles={styles}>
			<MovingCompanies />
		</BaseCompanyList>
	</AppProviders>
);
